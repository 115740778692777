<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-2">
            <div class="tw-flex tw-space-x-3 tw-w-full">
                <div class="tw-text-center tw-w-52 tw-h-32">
                    <UploadImage v-model="model.photo" ref="uploadimage" />
                    <!-- <small
                        ><i>{{ $t("shop.photo") }}</i></small
                    > -->
                </div>
                <div class="tw-w-full">
                    <div class="row tw-space-y-3">
                        <div class="col-md-12 tw-space-y-1">
                            <label class="required">{{
                                $t("shop.name")
                            }}</label>
                            <input
                                v-model.trim="model.shop_name"
                                type="text"
                                class="form-control"
                                :placeholder="$t('shop.name')"
                                :class="{
                                    'is-invalid': errors.has('shop_name')
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('shop_name')"
                            >
                                {{ errors.first("shop_name") }}
                            </div>
                        </div>
                        <div
                            class="col-md-12 tw-space-y-1"
                            v-if="$whois.admin()"
                        >
                            <label class="required">{{
                                $t("shop.agency")
                            }}</label>
                            <a-select
                                v-model="model.refer_from_agency_id"
                                :options="agencies"
                                class="tw-w-full"
                                showSearch
                                :filterOption="
                                    (input, option) =>
                                        option.componentOptions.children[0].text
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                "
                                :placeholder="$t('shop.agency')"
                            />
                            <div
                                class="tw-text-red-600"
                                v-if="errors.has('refer_from_agency_id')"
                            >
                                {{ errors.first("refer_from_agency_id") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
									<label>{{ $t("shop.sale") }}</label>
                    <a-select
                        v-model="model.saler_id"
                        :options="employee"
                        class="tw-w-full"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        :placeholder="$t('shop.agency')"
                    />
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('saler_id')"
                    >
                        {{ errors.first("saler_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{ $t("shop.phoneNumber") }}</label>
                    <input
                        v-model="model.phone_number"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('phone_number') }"
                        :placeholder="$t('shop.phoneNumber')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('phone_number')"
                    >
                        {{ errors.first("phone_number") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("shop.email") }}</label>
                    <input
                        v-model="model.email"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('email') }"
                        :placeholder="$t('shop.email')"
                    />
                    <div class="invalid-feedback" v-if="errors.has('email')">
                        {{ errors.first("email") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("shop.businessCategory")
                    }}</label>
                    <a-select
                        v-model="model.business_category_id"
                        :options="businessCategory"
                        class="tw-w-full"
                        :placeholder="$t('shop.businessCategory')"
                    />
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('business_category_id')"
                    >
                        {{ errors.first("business_category_id") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{ $t("shop.shopType") }}</label>
                    <a-select
                        v-model="model.shop_type_id"
                        :options="shopeType"
                        class="tw-w-full"
                        :placeholder="$t('shop.shopType')"
                    />
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('shop_type_id')"
                    >
                        {{ errors.first("shop_type_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("shop.standardFeeUsd") }}</label>
                    <ts-currency-input
                        type="number"
                        step="any"
                        v-model.number="model.standard_fee_usd"
                        class="form-control"
                        :placeholder="$t('shop.standardFeeUsd')"
                        :class="{
                            'is-invalid': errors.has('standard_fee_usd')
                        }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('standard_fee_usd')"
                    >
                        {{ errors.first("standard_fee_usd") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("shop.expressFeeUsd") }}</label>
                    <ts-currency-input
                        type="number"
                        step="any"
                        v-model.number="model.express_fee_usd"
                        class="form-control"
                        :placeholder="$t('shop.expressFeeUsd')"
                        :class="{ 'is-invalid': errors.has('express_fee_usd') }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('express_fee_usd')"
                    >
                        {{ errors.first("express_fee_usd") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("shop.standardDriverFeeUsd") }}</label>
                    <ts-currency-input
                        type="number"
                        step="any"
                        v-model.number="model.standard_driver_fee_usd"
                        class="form-control"
                        :placeholder="$t('shop.standardDriverFeeUsd')"
                        :class="{
                            'is-invalid': errors.has('standard_driver_fee_usd')
                        }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('standard_driver_fee_usd')"
                    >
                        {{ errors.first("standard_driver_fee_usd") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("shop.expressDriverFeeUsd") }}</label>
                    <ts-currency-input
                        type="number"
                        step="any"
                        v-model.number="model.express_driver_fee_usd"
                        class="form-control"
                        :placeholder="$t('shop.expressDriverFeeUsd')"
                        :class="{
                            'is-invalid': errors.has('express_driver_fee_usd')
                        }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('express_driver_fee_usd')"
                    >
                        {{ errors.first("express_driver_fee_usd") }}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("shop.allowService")
                    }}</label>
                    <a-select
                        v-model="model.service_type_allow"
                        :options="serviceType"
                        mode="multiple"
                        class="tw-w-full"
                        :placeholder="$t('shop.serviceType')"
                    />
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('service_type_allow')"
                    >
                        {{ errors.first("service_type_allow") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("shop.feePerPackage")
                    }}</label>
                    <ts-currency-input
                        type="number"
                        step="any"
                        v-model.number="model.fee_per_package"
                        :readonly="!model.is_stock_at_jd"
                        class="form-control"
                        :placeholder="$t('shop.feePerPackage')"
                        :class="{ 'is-invalid': errors.has('fee_per_package') }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('fee_per_package')"
                    >
                        {{ errors.first("fee_per_package") }}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <ts-checkbox v-model="model.is_active">{{
                                $t("shop.active")
                            }}</ts-checkbox>
                        </div>
                        <div class="col-md-6">
                            <ts-checkbox
                                @change="() => (model.fee_per_package = 0.25)"
                                v-model="model.is_stock_at_jd"
                                >{{ $t("shop.stockAtJd") }}</ts-checkbox
                            >
                        </div>
                        <div class="col-md-6">
                            <ts-checkbox v-model="model.acknowledge_tc">{{
                                $t("shop.approved")
                            }}</ts-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("shop.servicePaidBy") }}</label>
                    <a-select
                        v-model="model.service_paid_by"
                        class="tw-w-full"
                        :options="[
                            { value: 2, label: 'Shop' },
                            { value: 1, label: 'Buyer' }
                        ]"
                    ></a-select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('service_paid_by')"
                    >
                        {{ errors.first("service_paid_by") }}
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";
import UploadImage from "./upload-image.vue";
import { mapState } from "vuex";
import TsCurrencyInput from "../../../../components/common/TsCurrencyInput.vue";

export default {
    name: "basicInfoShop",
    components: {
        UploadImage,
        TsCurrencyInput
    },
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("agency/shop", ["formModels"]),
        agencies() {
            if (!this.formModels.agencies) return [];
            return this.formModels.agencies.map(el => ({
                value: el.agency_id,
                label: el.agency_name
            }));
        },
        serviceType() {
            if (!this.formModels.serviceType) return [];
            return this.formModels.serviceType.map(el => ({
                value: el.service_type_id,
                label: el.service_type_name_en
            }));
        },
        businessCategory() {
            if (!this.formModels.businessCategory) return [];

            return this.formModels.businessCategory.map(el => ({
                value: el.business_category_id,
                label: el.business_category
            }));
        },
        shopeType() {
            if (!this.formModels.shopeType) return [];

            return this.formModels.shopeType.map(el => ({
                value: el.shop_type_id,
                label: el.shop_type
            }));
        },
        employee() {
            if (!this.formModels.employee) return [];

            return this.formModels.employee.map(el => ({
                value: el.employee_id,
                label: el.employee_name_kh + ' ' + el.employee_name_en
            }));
        }
    },
    methods: {},
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
